import ListingTagsList from "./ListingTagsList";
import Link from "next/link";

const PostMiniListing = ({
  slug,
  title,
  category,
  date,
  locale,
  description,
  type = "default",
}) => {
  return (
    <Link
      href={"/yazilar/" + slug}
      className={`js-postmain-mini flex gap-4 h-full ${
        type === "default" ? "px-6 py-6" : "py-4 px-4"
      } transition-colors duration-100 ease-out border border-gray-200 rounded-md cursor-pointer sm:gap-6 hover:border-primaryLight`}
    >
      <div>
        <div>
          <ListingTagsList
            category={category}
            date={type === "default" ? date : null}
            locale={locale}
            type={type === "default" ? "YAZI" : ""}
          />
        </div>
        <div
          className={`font-semibold ${
            type === "default" ? "text-lg" : ""
          } mt-2`}
        >
          {title}
        </div>
        <div className="text-sm text-neutral-400">{description}</div>
      </div>
    </Link>
  );
};

export default PostMiniListing;
